// productActions.js

import axios from 'axios';
import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
} from './constants';

const API_BASE_URL = 'https://busy-erin-coyote-toga.cyclic.app/api';

export const getProducts = (search,cat,tags,skip,limit) => {
  if(!search){
    search =''
  }
  if(!cat){
    cat =[];
  }
  if(!skip){
    skip=0;
  }
  if(!limit){
    limit=8;
  }
  return  (dispatch) => {
    dispatch({ type: GET_PRODUCTS_REQUEST });

    try {
      setTimeout(async()=>{
      const response = await axios.get(`${API_BASE_URL}/products?search=${search}&category=${cat}&tags=${tags}&skip=${skip}&limit=${limit}`);
      const products = response.data;
      dispatch({ type: GET_PRODUCTS_SUCCESS, payload: products })
      },1000);
    } catch (error) {
      console.error('Error:', error);
      dispatch({ type: GET_PRODUCTS_FAILURE, payload: error.message });
    }
  };
};

