import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../App/feature/products/actions';
import ProductDetail from '../component/product/detailProduct';
import Loading from '../component/loading/loading';

function ProductDetailPage(){
    const dispatch = useDispatch();
    const { products, auth } = useSelector((state) => state);
    const { error, loading } = products;
    useEffect(() => {
        dispatch(getProducts());
      }, [dispatch]);
      if(products.productItems.data){
      console.log(products.productItems.data[0])
      }
    return(
        <>
        {loading && <Loading />}
        {!loading && !error && products.productItems.data && <ProductDetail product={products.productItems.data[0]} isLoggedIn={!!auth.token}/>}
        </>
    )

}

export default ProductDetailPage;