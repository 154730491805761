import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCart } from '../../App/feature/cart/actions';
import { getCart } from '../../App/feature/cart/actions';
import {
  Typography,
  Button
} from "@material-tailwind/react";
import starImage from "../../assets/images/star.png";
import Popup from '../PopUp/popUp';
import Loading from '../loading/loading';

export default function ProductDetail(props) {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state);
  const cartItems = cart.cartItems;
  const ratingStars = [];
  for (let i = 1; i <= props.product.rating; i++) {
    ratingStars.push(<img key={i} src={starImage} alt="" className="w-4 h-4" />);
  }
  const price = props.product.price.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' });

  const [showMustLogin, setShowMustLogin] = useState(false);
  const [status, setStatus] = useState(props.status);
  const [loading,SetLoading] = useState(false);

  useEffect(() => {
    dispatch(getCart());
  }, [dispatch]);
  
  const handleAddToCart = () => {
    if (props.isLoggedIn) {
      SetLoading(true);
      const updatedItems = [
        ...cartItems.map((item) => ({ product: {_id:item.product._id}, qty: item.qty })),
        {
          product: {
            _id: props.product._id
          },
          qty: 1
        }
      ];

      const cartData = {
        items: updatedItems
      };
      dispatch(updateCart(cartData));
    setTimeout(() => {
      SetLoading(false);
      setStatus(true);
    }, 2000);
    } else {
      setShowMustLogin(true);
    }
  };

  const handleMustLogin = () => {
    setShowMustLogin(true);

    setTimeout(() => {
      setShowMustLogin(false);
    }, 2000);
  };


  return (
    <div className="container mx-auto py-8">
      {props.loading ? (
        <Loading />
      ) : props.error ? (
        <Typography color="red">{props.error}</Typography>
      ) : (
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2">
            <img src={props.product.image_url ? props.product.image_url : "https://iber.or.id/wp-content/themes/consultix/images/no-image-found-360x250.png"} alt={props.product.name} className="w-full" />
          </div>
          <div className="md:w-1/2 md:pl-8 mt-4 md:mt-0">
            <Typography className="text-3xl font-medium">{props.product.name}</Typography>
            <div className="flex items-center">
              {ratingStars}
              <Typography color="gray-500" className="ml-2">{props.product.rating} ({props.product.reviews} reviews)</Typography>
            </div>
            <Typography className="mt-4 text-gray-700">{props.product.description}</Typography>
            <Typography className="mt-4 font-bold text-xl">{price}</Typography>
            <div className="flex items-center mt-4">
              {props.isLoggedIn ? (
                <Button
                  ripple={false}
                  className="bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-500 focus:outline-none"
                  disabled={status}
                  onClick={handleAddToCart}
                >
                  {status ? "Added to Cart" : "Add to Cart"}
                </Button>
              ) : (
                <Button
                  ripple={false}
                  className="bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-500 focus:outline-none"
                  onClick={handleMustLogin}
                >
                  Add to Cart
                </Button>
              )}
              {showMustLogin && (
                <Popup
                  title="Log In Required"
                  message="Please log in before adding to cart!"
                  type="warning"
                  setShow={setShowMustLogin}
                />
              )}
              {loading && (
                <Popup
                  title="Adding to Cart"
                  message="Please wait..."
                  type="info"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}